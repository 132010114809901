/* Importar las fuentes de Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Oswald:wght@400;700&family=Raleway:wght@400;700&family=Roboto+Condensed:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

/* Definir colores personalizados */
:root {
  --primary-color: #ffffff;
  --secondary-color: #ffffff;
  --text-color: #ffffff;
  --background-color: #1f1f1f;
  --selected-color: #ffffff;
  --light-color: #2b2b2b;
  --dark-color: #ffffff;
  --schedule-btn-color: #39ff14;
  --accent-color: #39ff14 ; /* Color de acento para detalles */
}

/* Estilos generales */
body, html {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  width: 100%;
  height: 100%;
  color: var(--text-color);
  background: linear-gradient(135deg, #1f1f1f, #2b2b2b);
}

/* Estilos para el encabezado */
.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: linear-gradient(135deg, #1f1f1f, #2b2b2b);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 180px;
  height: auto;
  text-shadow: 0 0 10px rgba(33, 33, 33, 0.3);
}

.header-text {
  font-family: 'Bebas Neue', 'cursive', sans-serif;
  font-size: 64px;
  color: #fff;
  margin: 0;
  text-shadow: 0 0 10px rgb(95, 95, 95);
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Estilos para el banner superior */
.navbar {
  padding: 8px 0;
}

/* Estilos para el contenido principal */
.main-content {
  min-height: calc(100vh - 150px);
  padding-top: 20px;
}

/* Estilos para el carrusel */
.carousel-wrapper {
  width: 80%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.carousel-wrapper .slick-slide {
  transition: transform 0.3s ease-in-out;
}

.carousel-wrapper .slick-slide:hover {
  transform: scale(1.05);
}

/* Estilos para las imágenes de los servicios */
.service-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
}

/* Estilos para el listado de servicios */
.service-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.service-title {
  font-weight: bold;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  margin-bottom: 30px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--light-color);
  color: var(--text-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.service-title:hover,
.service-title.active {
  background-color: var(--accent-color);
  color: #fff;
}

/* Estilos para los detalles del servicio */
.service-details {
  text-align: left;
  margin-right: auto;
  margin-top: 20px;
  text-shadow: 0 0 10px rgb(54, 54, 54);
}

.service-details .card {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: var(--light-color);
  border-radius: 5px;
  text-shadow: 0 0 10px rgb(95, 95, 95);
}

.service-details .card-body {
  background-color: var(--light-color);
  color: var(--dark-color);
  text-shadow: 0 0 10px rgb(95, 95, 95);
}

.service-details .card-title {
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 38px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.service-details .card-text {
  font-size: 20px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.service-details .card-text:last-child {
  font-size: 20px;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Estilos para las flechas del carrusel */
.slick-arrow {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: background-color 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.slick-arrow:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.slick-next {
  right: 10px;
}

.slick-prev {
  left: 10px;
}

/* Animación de transición para las imágenes del carrusel */
.slick-slide {
  transition: opacity 0.5s ease-in-out;
}

.slick-slide.slick-active {
  opacity: 1;
}

.slick-slide:not(.slick-active) {
  opacity: 0;
}

/* Estilos para el botón "Schedule Now" */
.schedule-btn {
  display: block;
  margin: 0 auto;
  background-color: var(--schedule-btn-color);
  border-color: var(--schedule-btn-color);
  color: #000000; /* Cambiar el color del texto a blanco */
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 5px;
}

.schedule-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

/* Estilos para el formulario del modal */
.modal-body form {
  display: flex;
  flex-direction: column;
}

.modal-body .form-label {
  font-weight: bold;
}

.modal-body .form-control {
  margin-bottom: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Estilos para el modal */
.modal-content {
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal-title {
  color: #000;
  font-weight: bold;
}

.modal-body {
  color: #000;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* Estilos para los títulos del formulario */
.form-title {
  color: #000;
}

/* Estilos para dispositivos móviles */
@media (max-width: 767px) {
  .app-header {
    padding: 10px;
  }

  .header-text {
    font-size: 36px;
  }

  .main-content {
    padding-top: 10px;
  }

  .carousel-wrapper {
    width: 100%;
    margin-left: 0;
  }

  .col-md-4 {
    margin-bottom: 20px;
  }

  /* Estilos para el listado de servicios */
.service-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.service-title {
  font-weight: bold;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  margin-bottom: 30px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--light-color);
  color: var(--text-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.service-title:hover,
.service-title.active {
  background-color: var(--accent-color);
  color: #fff; /* Cambiar el color del texto a blanco cuando esté activo */
}
}